/* eslint-disable max-lines */

import { useEffect, useRef, useState } from 'react';

import {
  FullScreenMessage,
  InAppMessage,
  InAppMessageButton,
  ModalMessage,
} from '@braze/web-sdk';
import {
  Button,
  ChakraProvider,
  Flex,
  Img,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import { theme } from '@r-client/republic/ui/theme';
import { useAuth } from '@r-client/shared/feature/auth';
import { BigCross } from '@r-client/shared/ui/icons';
import { useClientRender } from '@r-client/shared/util/components';
import { useAppConfig } from '@r-client/shared/util/core';

import { useBrazeFeatureFlag } from '../../utils/use-braze-feature-flag';

const BLANK_LINK_TARGET = 'BLANK';
const BUTTON_CLOSE_ACTION = 'NONE';

const NONCE = 'DhcnhD3khTMePgXw';

export const Braze = observer(function Braze() {
  const router = useRouter();
  const isBrazeFeatureFeatureEnabled = useBrazeFeatureFlag();
  const { brazeApiKey, brazeBaseUrl } = useAppConfig();
  const { viewer } = useAuth();
  const isClientRender = useClientRender();
  const brazeRef = useRef<{
    logInAppMessageClick: (inAppMessage: InAppMessage) => boolean;
    logInAppMessageButtonClick: (
      button: InAppMessageButton,
      inAppMessage: InAppMessage
    ) => boolean;
  } | null>(null);
  const [message, setMessage] = useState<
    FullScreenMessage | ModalMessage | null
  >(null);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const routesException =
    router.pathname === '/campaigns' ||
    router.pathname === '/investor/onboarding' ||
    router.pathname === '/investor/onboarding' ||
    router.pathname.endsWith('/onboarding/team') ||
    router.pathname.endsWith('/onboarding/cik');

  useEffect(() => {
    import('@braze/web-sdk').then(
      ({
        initialize,
        changeUser,
        openSession,
        logInAppMessageClick,
        logInAppMessageButtonClick,
        subscribeToInAppMessage,
      }) => {
        if (
          !viewer?.isAuthenticated ||
          !isBrazeFeatureFeatureEnabled ||
          routesException
        ) {
          return;
        }

        const init = initialize?.(`${brazeApiKey}`, {
          baseUrl: `${brazeBaseUrl}`,
          allowUserSuppliedJavascript: true,
          contentSecurityNonce: `nonce-${NONCE}`,
        });

        subscribeToInAppMessage((inAppMessage) => {
          setMessage(inAppMessage as FullScreenMessage | ModalMessage);
          setIsOpen(!!inAppMessage);
        });

        changeUser(viewer.info.id);

        openSession();

        setIsInit(init);

        brazeRef.current = {
          logInAppMessageClick: logInAppMessageClick,
          logInAppMessageButtonClick: logInAppMessageButtonClick,
        };
      }
    );
  }, [brazeApiKey, brazeBaseUrl, viewer, isBrazeFeatureFeatureEnabled]);
  function handleClose() {
    setIsOpen(false);
  }

  function handleMessageClick() {
    brazeRef.current?.logInAppMessageClick(message as InAppMessage);
  }

  function handleButtonClick(button: InAppMessageButton) {
    return () => {
      brazeRef.current?.logInAppMessageButtonClick(
        button,
        message as InAppMessage
      );

      if (button.clickAction === 'NONE') {
        setIsOpen(false);
      }
    };
  }

  if (!viewer?.isAuthenticated || !message || !isClientRender) {
    return null;
  }

  if (isClientRender && isInit) {
    return (
      <ChakraProvider theme={theme}>
        <Modal variant="republic" isOpen={isOpen} onClose={handleClose}>
          <ModalOverlay />
          <ModalContent
            w={{ base: '343px', xs: '550px' }}
            maxWidth="550px"
            borderRadius="4"
            p="0"
          >
            <ModalHeader p="0">
              {message.imageUrl && (
                <Flex
                  w={{ base: '343px', xs: '550px' }}
                  h={{ base: '228px', xs: '366px' }}
                >
                  <Img
                    objectFit="contain"
                    alt="Braze campaign image"
                    src={message.imageUrl}
                  />
                </Flex>
              )}
            </ModalHeader>
            <ModalCloseButton
              bgColor="white"
              borderRadius="50%"
              color="brand.gray.d8"
              alignItems="center"
              justifyContent="center"
            >
              <BigCross fill="brand.gray.88" />
            </ModalCloseButton>
            <ModalBody
              data-testid="content"
              pb="0"
              pr="8"
              pt="8"
              pl="8"
              mb="8"
              onClick={handleMessageClick}
            >
              <Flex direction="column">
                <Flex
                  mb="4"
                  justifyContent={
                    message.headerAlignment &&
                    message.headerAlignment.toLowerCase()
                  }
                >
                  <Text
                    fontWeight="bold"
                    fontSize="25px"
                    lineHeight="28px"
                    letterSpacing="-0.7px"
                  >
                    {message.header}
                  </Text>
                </Flex>
                <Flex>
                  <Text fontSize="md" lineHeight="24px" color="brand.grey.66">
                    {message.message}
                  </Text>
                </Flex>
              </Flex>
            </ModalBody>
            <ModalFooter
              pt="0"
              pl="8"
              pr="8"
              pb="8"
              justifyContent="flex-start"
            >
              <Flex
                minWidth="100%"
                direction={{ base: 'column', xs: 'row' }}
                alignItems={{ base: 'center', xs: 'flex-start' }}
              >
                {message.buttons &&
                  message.buttons.map((button, i) => {
                    const target =
                      message?.openTarget === BLANK_LINK_TARGET
                        ? '_blank'
                        : '_self';

                    return (
                      <Link
                        data-id={button.id}
                        key={button.id}
                        target={target}
                        mr={{ base: '0', xs: '4' }}
                        mb={{ base: '4', xs: '4' }}
                        textDecoration="none"
                        _hover={{
                          textDecoration: 'none',
                        }}
                        onClick={handleButtonClick(button)}
                        {...(i !== 0
                          ? { minWidth: { base: '100%', xs: 'auto' } }
                          : {})}
                        {...(i === message?.buttons.length - 1
                          ? { marginBottom: '0' }
                          : {})}
                        {...(BUTTON_CLOSE_ACTION !== button.clickAction
                          ? { href: button.uri }
                          : {})}
                        variant="republic-default"
                        size="republic-default"
                      >
                        <Button
                          variant={i === 0 ? 'republic-primary' : 'outline'}
                          minWidth={{ base: '100%', xs: 'auto' }}
                          width="100%"
                          size="md"
                        >
                          {button.text}
                        </Button>
                      </Link>
                    );
                  })}
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </ChakraProvider>
    );
  }

  return null;
});
