import {
  type ComponentStyleConfig,
  theme as originalTheme,
} from '@chakra-ui/theme';

export const Link: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'normal',
    color: 'grey.600',
    textDecoration: 'underline',
    _focus: {
      boxShadow: 'none',
    },
  },
  variants: {
    'republic-default': {
      ...originalTheme.components.Link.baseStyle,
      textDecoration: 'none',
      color: 'inherit',
    },
    'republic-underlined': {
      ...originalTheme.components.Link.baseStyle,
      textDecoration: 'underline',
      color: 'inherit',
    },
  },
  sizes: {
    'republic-default': {},
    'body-xl': {
      fontSize: '2xl',
      lineHeight: '2rem',
      letterSpacing: 'widest',
    },
    'body-lg': {
      fontSize: 'lg',
      lineHeight: '1.5rem',
      letterSpacing: 'extraWide',
    },
    'body-md': {
      fontSize: 'md',
      lineHeight: '1.5rem',
      letterSpacing: 'superWide',
    },
    'body-sm': {
      fontSize: 'sm',
      lineHeight: '1rem',
      letterSpacing: 'ultraWide',
    },
    'body-xs': {
      fontSize: 'xs',
      lineHeight: '1rem',
    },
  },
  defaultProps: {
    size: 'body-md',
  },
};
